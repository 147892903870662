<template>
	<section
		class="accordion"
		:class="[
			{ 'accordion--white': tWhite },
			{ 'accordion--border': tBorder },
			{ 'accordion--closed': !contentVisible },
			{ 'accordion--transparent': tTransparent },
			{ 'accordion--narrow-gap': narrowGap },
		]"
	>
		<header class="accordion__header" :class="{ 'header-multiline': tMultiline }">
			<div v-if="!!$slots.customActionsLeft" class="accordion__header__actions-left">
				<slot name="customActionsLeft"></slot>
			</div>
			<h3 class="accordion__title" :class="{'accordion__title--left-actions': !!$slots.customActionsLeft}" :title="titleAttr" role="button" @click.prevent="clickListener">
				<Fa
					weight="r"
					icon="angle-right"
					class="accordion-angle-icon"
					:class="{ 'accordion-angle-icon-rotated': contentVisible }"
				/>
				<span class="accordion__title__text" :class="{ 'accordion-title-multiline': tMultiline }">
					{{ title }}
				</span>
			</h3>
			<div class="accordion__header__actions">
				<div class="accordion__header__infos" @click="contentVisible ? null : clickListener()">
					<slot name="headerInfo"></slot>
				</div>
				<div class="accordion__header__icons" @click="contentVisible ? null : clickListener()">
					<AppInfoButton :uuid="uuid" v-if="uuid" />
					<slot name="headerIcon"></slot>
				</div>
				<div v-if="!!$slots.customActions" class="accordion__header__custom-actions">
					<slot name="customActions"></slot>
				</div>
			</div>
		</header>

		<div v-if="!!$slots.alwaysVisible" class="accordion__always-visible">
			<slot name="alwaysVisible"></slot>
		</div>

		<transition
			name="accordion"
			@enter="startTransition"
			@after-enter="endTransition"
			@before-leave="startTransition"
			@after-leave="endTransition"
		>
			<div v-show="contentVisible" class="accordion__animation-container">
				<div class="accordion__content">
					<slot></slot>
				</div>
			</div>
		</transition>
	</section>
</template>

<script>
import AppInfoButton from '@/core/components/AppInfoButton';

export default {
	name: 'AppAccordion',
	components: {
		AppInfoButton,
	},
	emits: ['toggle'],
	props: {
		title: {
			type: String,
			default: '',
		},
		titleAttr: {
			type: String,
			default: 'Öppna / Stäng',
		},
		initialOpen: {
			type: [Boolean, null],
			default: null,
		},
		uuid: {
			type: String,
			default: '',
		},
		tWhite: {
			type: Boolean,
			default: false,
		},
		tBorder: {
			type: Boolean,
			default: false,
		},
		tMultiline: {
			type: Boolean,
			default: false,
		},
		tTransparent: {
			type: Boolean,
			default: false,
		},
		narrowGap: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		const mql = window.matchMedia('(min-width: 1024px)');
		return {
			isOpen: this.initialOpen !== null ? this.initialOpen : mql.matches,
		};
	},
	computed: {
		contentVisible() {
			return this.isOpen;
		},
	},
	methods: {
		clickListener() {
			this.isOpen = !this.isOpen;
			this.$emit('toggle', this.isOpen);
		},
		inputInvalidListener() {
			if (!this.isOpen) {
				this.isOpen = true;
			}
		},
		addInputInvalidListeners() {
			this.$el.querySelectorAll('input, textarea, select').forEach((input) => {
				if ('accordionInputListener' in input.dataset) {
					return;
				}

				input.addEventListener('invalid', this.inputInvalidListener);
				input.dataset.accordionInputListener = 'true';
			});
		},
		startTransition(el) {
			el.style.height = el.scrollHeight + 'px';
		},
		endTransition(el) {
			el.style.height = '';
		},
	},
	created() {
		this.inputInvalidListener = this.inputInvalidListener.bind(this);
	},
	mounted() {
		this.addInputInvalidListeners();
	},
	updated() {
		this.addInputInvalidListeners();
	},
};
</script>

<style scoped>
.accordion {
	box-shadow: 0 2px 10px rgba(3, 52, 99, 0.05);
	width: 100%;
	border-radius: var(--panelBorderRadius);
	background: var(--panelBg);
}

.accordion__always-visible {
	background: var(--panelAlwaysVisibleBg);
	padding: var(--panelPadding);
}

.accordion__header {
	display: flex;
	align-items: center;
	gap: calc(var(--panelPadding) / 2);
	background: var(--panelHeaderBg);
	color: var(--colorKaraten);
	border-top-right-radius: var(--panelBorderRadius);
	border-top-left-radius: var(--panelBorderRadius);
	cursor: pointer;
}

.accordion-angle-icon {
	transition: all 0.35s ease-in-out;
}

.accordion-angle-icon-rotated {
	transform: rotate(90deg);
}

.accordion__title {
	display: flex;
	align-items: center;
	gap: calc(var(--panelPadding) / 2);
	flex-basis: 100%;
	padding: var(--panelPadding) 0 var(--panelPadding) var(--panelPadding);
	margin: 0;
	min-width: 0;
}

.accordion__title__text {
	font-size: 13px;
	line-height: 1.3;
}

.accordion__header__actions {
	flex: 1 0 auto;
	padding-right: 10px;
}

.accordion__header__custom-actions button {
	width: 8px;
	height: 8px;
}

.accordion__header__actions,
.accordion__header__icons,
.accordion__header__infos,
.accordion__header__custom-actions {
	display: flex;
	align-items: center;
	gap: calc(var(--panelPadding) / 2);
	white-space: nowrap;
}

.accordion__header__actions-left {
	padding-left: 15px;
}

.accordion__title--left-actions {
	padding-left: 0;
}

.accordion__content {
	display: flex;
	flex-direction: column;
	gap: var(--panelGap);
	padding: var(--panelPadding);
}

.accordion--white .accordion__header {
	background: var(--panelBg);
	color: var(--textColor);
}

.accordion--border {
	border: 1px solid var(--panelBorderColor);
	box-shadow: none;
}

.accordion--border .accordion__header {
	background: var(--panelBg);
	color: var(--textColor);
}

.accordion--closed {
}
.accordion--transparent {
	background: transparent;
	box-shadow: none;
}

.accordion--narrow-gap .accordion__content {
	gap: var(--panelGapNarrow);
}

.accordion--transparent .accordion__content {
	padding: 0;
}
</style>
<style>
/* Remove this when we not using .content-container anymore */
.accordion__always-visible > .content-container,
.accordion__content > .content-container {
	display: flex;
	flex-direction: column;
	gap: var(--panelGap);

	background: transparent;
	padding: 0;
	margin: 0;
	list-style: none;
	border-radius: 0;
}
</style>
