<template>
	<div class="editor">
		<div v-if="menuBar" class="editor-menubar">
			<div class="editor-buttons-container">
				<button
					type="button"
					title="Fetstil"
					:class="{ 'is-active': editor.isActive('bold') }"
					@click.prevent="editor.chain().focus().toggleBold().run()"
				>
					<Fa weight="r" icon="bold" />
				</button>

				<button
					type="button"
					title="Kursiv"
					:class="{ 'is-active': editor.isActive('italic') }"
					@click.prevent="editor.chain().focus().toggleItalic().run()"
				>
					<Fa weight="r" icon="italic" />
				</button>

				<button
					type="button"
					title="Genomstruken"
					:class="{ 'is-active': editor.isActive('strike') }"
					@click.prevent="editor.chain().focus().toggleStrike().run()"
				>
					<Fa weight="r" icon="strikethrough" />
				</button>

				<button
					type="button"
					title="Understruken"
					:class="{ 'is-active': editor.isActive('underline') }"
					@click.prevent="editor.chain().focus().toggleUnderline().run()"
				>
					<Fa weight="r" icon="underline" />
				</button>

				<button
					type="button"
					title="Stycke"
					:class="{ 'is-active': editor.isActive('paragraph') }"
					@click.prevent="editor.chain().focus().setParagraph().run()"
				>
					<Fa weight="r" icon="paragraph" />
				</button>

				<button
					type="button"
					title="Rubrik nivå 1"
					:class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
					@click.prevent="editor.chain().focus().toggleHeading({ level: 1 }).run()"
				>
					H1
				</button>

				<button
					type="button"
					title="Rubrik nivå 2"
					:class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
					@click.prevent="editor.chain().focus().toggleHeading({ level: 2 }).run()"
				>
					H2
				</button>

				<button
					type="button"
					title="Rubrik nivå 3"
					:class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
					@click.prevent="editor.chain().focus().toggleHeading({ level: 3 }).run()"
				>
					H3
				</button>

				<button
					type="button"
					title="Oordnad lista"
					:class="{ 'is-active': editor.isActive('bulletList') }"
					@click.prevent="editor.chain().focus().toggleBulletList().run()"
				>
					<Fa weight="r" icon="list-ul" />
				</button>

				<button
					title="Ordnad lista"
					:class="{ 'is-active': editor.isActive('orderedList') }"
					@click.prevent="editor.chain().focus().toggleOrderedList().run()"
				>
					<Fa weight="r" icon="list-ol" />
				</button>

				<button
					type="button"
					title="Avdelare"
					@click.prevent="editor.chain().focus().setHorizontalRule().run()"
				>
					<Fa weight="r" icon="horizontal-rule" />
				</button>
			</div>

			<div class="editor-buttons-container">
				<button title="Ångra" @click.prevent="editor.chain().focus().undo().run()">
					<Fa weight="r" icon="undo" />
				</button>

				<button type="button" title="Gör om" @click.prevent="editor.chain().focus().redo().run()">
					<Fa weight="r" icon="redo" />
				</button>

				<button
					type="button"
					title="Länk"
					@click.prevent="showLinkDialog(editor.getAttributes('link').href)"
					:class="{ 'is-active': editor.isActive('link') }"
				>
					<Fa weight="r" icon="link" />
				</button>

				<button
					type="button"
					title="Bild"
					@click.prevent="showImageDialog()"
					:class="{ 'is-active': editor.isActive('image') }"
				>
					<Fa weight="r" icon="image" />
				</button>

				<button
					type="button"
					title="Ny tabell"
					@click.prevent="
						editor.chain().focus().insertTable({ rows: 2, cols: 2, withHeaderRow: false }).run()
					"
				>
					<Fa weight="r" icon="table" />
				</button>

				<button
					type="button"
					title="Lägg till tabellrad ovan"
					@click.prevent="editor.chain().focus().addRowBefore().run()"
				>
					<Fa weight="r" icon="border-top" />
				</button>

				<button
					type="button"
					title="Lägg till tabellrad under"
					@click.prevent="editor.chain().focus().addRowAfter().run()"
				>
					<Fa weight="r" icon="border-bottom" />
				</button>
				<button
					type="button"
					title="Lägg till kolumn vänster"
					@click.prevent="editor.chain().focus().addColumnBefore().run()"
				>
					<Fa weight="r" icon="border-left" />
				</button>
				<button
					type="button"
					title="Lägg till kolumn höger"
					@click.prevent="editor.chain().focus().addColumnAfter().run()"
				>
					<Fa weight="r" icon="border-right" />
				</button>
				<button
					type="button"
					title="Ta bort tabellrad"
					@click.prevent="editor.chain().focus().deleteRow().run()"
				>
					<Fa weight="r" icon="border-center-h" />
				</button>
				<button
					type="button"
					title="Ta bort kolumn"
					@click.prevent="editor.chain().focus().deleteColumn().run()"
				>
					<Fa weight="r" icon="border-center-v" />
				</button>
				<button
					type="button"
					title="Ta bort tabell"
					@click.prevent="editor.chain().focus().deleteTable().run()"
				>
					<Fa weight="r" icon="border-none" />
				</button>
			</div>
			<AppAccordion v-if="interpolators.length" title="Nyckelord" :initial-open="false" class="interpolators-accordion">
				<div class="interpolators-accordion-content content-container fa-border">
					<AppList>
						<AppListItem
							v-for="(interpolatorCategory, index) in interpolators"
							:key="index"
						>
							<AppAccordion
								:title="interpolatorCategory.name" t-border
								:initial-open="false"
								t-transparent
								narrow
							>
								<div class="content-container">
									<p class="input-help">
										<pre v-if="interpolatorCategory.description">{{ interpolatorCategory.description }}</pre>
									</p>
									<AppList>
										<AppListItem
											v-for="(interpolator, index) in interpolatorCategory.interpolators"
											:key="index"
										>
											<div class="interpolator__row__content" @click.prevent="addVariable(interpolator.string)">
												<strong>{{ interpolator.description }}</strong>
												<span class="t-neutral">{{ interpolator.string }}</span>
											</div>

											<template #actions>
												<div class="add-variable-icon" @click.prevent="addVariable(interpolator.string)">
													<Fa weight="r" icon="plus-circle" class="t-success" />
												</div>
												<Fa v-if="interpolator.example" weight="r" icon="eye" v-tippy="{content: interpolator.example, allowHTML: true}" />
											</template>
										</AppListItem>
									</AppList>
								</div>
							</AppAccordion>
						</AppListItem>
					</AppList>

				</div>
			</AppAccordion>
		</div>
		<div v-if="isLinkDialogOpen || isImageDialogOpen">
			<AppModal v-if="isLinkDialogOpen" :footer="false" @close="closeLinkDialog">
				<template v-slot:header>
					<h1>Hantera länk</h1>
				</template>
				<form @submit.prevent="setLinkUrl(linkUrl)">
					<p class="form-group">
						<label>
							Länkadress:
							<input type="url" v-model="linkUrl" placeholder="https://" required />
						</label>
					</p>
					<footer>
						<button type="submit" class="button t-success">OK</button>
						<button
							v-if="editor.isActive('link')"
							type="button"
							class="t-danger"
							@click.prevent="setLinkUrl(null)"
						>
							Ta bort länk
						</button>
					</footer>
				</form>
			</AppModal>

			<AppModal v-if="isImageDialogOpen" :footer="false" @close="closeImageDialog">
				<template v-slot:header>
					<h1>Hantera bild</h1>
				</template>
				<form @submit.prevent="setImageUrl(imageUrl)">
					<p class="form-group">
						<label>
							Bildadress:
							<input type="url" v-model="imageUrl" placeholder="https://" required />
						</label>
					</p>
					<footer>
						<button type="submit" class="button t-success">OK</button>
					</footer>
				</form>
			</AppModal>
		</div>

		<EditorContent class="editor-content" v-bind="$attrs" :class="{ disabled: !editable }" :editor="editor" />
	</div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
import Placeholder from '@tiptap/extension-placeholder';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import AppList from '@/core_2/components/app_list/AppList.vue';
import AppListItem from '@/core_2/components/app_list/AppListItem.vue';
import { BulletList } from '@tiptap/extension-bullet-list';

export default {
	name: 'AppEditor',
	inheritAttrs: false,
	components: {
		AppListItem,
		AppList,
		EditorContent,
	},
	props: {
		value: String,
		placeholder: {
			type: String,
			default: '',
		},
		interpolators: {
			type: Array,
			default: () => [],
		},
		adjustGridStyle: {
			type: Boolean,
			default: false,
		},
		menuBar: {
			type: Boolean,
			default: true,
		},
		editable: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			editor: new Editor({
				extensions: [
					StarterKit,
					BulletList.extend({
						addAttributes() {
							return {
								style: {
									parseHTML: (element) => element.style?.cssText,
								},
							};
						},
						renderHTML(props) {
							return ['ul', props.HTMLAttributes, 0];
						},
					}),
					Underline,
					Link,
					Image,
					Table.configure({
						HTMLAttributes: {
							class: 'tiptap-table',
						},
					}),
					TableRow,
					TableCell,
					TableHeader,
					Placeholder.configure({
						emptyEditorClass: 'is-editor-empty',
						emptyNodeClass: 'is-empty',
						showOnlyWhenEditable: true,
					}),
				],
				content: this.value,
			}),
			linkUrl: null,
			isLinkDialogOpen: false,
			imageUrl: null,
			isImageDialogOpen: false,
		};
	},
	watch: {
		value: {
			handler(newValue) {
				if (newValue !== this.editor.getHTML()) {
					this.editor.commands.setContent(newValue);
				}
			},
			immediate: true,
		},
		editable: {
			handler(val) {
				this.editor.setEditable(val);
			},
			immediate: true,
		},
	},
	mounted() {
		this.editor.on('update', () => {
			this.$emit('input', this.editor.getHTML());
		});
	},
	beforeDestroy() {
		this.editor.destroy();
	},
	methods: {
		showLinkDialog(href) {
			this.linkUrl = href;
			this.isLinkDialogOpen = true;
		},
		closeLinkDialog() {
			this.linkUrl = null;
			this.isLinkDialogOpen = false;
		},
		setLinkUrl(url) {
			if (!url || url === '') {
				this.editor.chain().focus().extendMarkRange('link').unsetLink().run();
			} else {
				this.editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
			}
			this.closeLinkDialog();
		},
		showImageDialog() {
			this.isImageDialogOpen = true;
		},
		closeImageDialog() {
			this.imageUrl = null;
			this.isImageDialogOpen = false;
		},
		setImageUrl(url) {
			this.editor.chain().focus().setImage({ src: url }).run();
			this.closeImageDialog();
		},
		addVariable(string) {
			this.editor.chain().focus().insertContent(` ${string} `).run();
		},
	},
};
</script>

<style>
.editor {
	display: flex;
	flex-direction: column;
	border: 1px solid #ccc;
	border-radius: 4px;
}
.editor-menubar {
	position: sticky;
	top: 88px;
	background: #f1f1f1;
	border-bottom: none;
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
	z-index: 1;
}

.modal-body .editor-menubar {
	top: -15px;
}

.editor-menubar .editor-buttons-container {
	display: flex;
}

.editor-menubar .editor-buttons-container + .editor-buttons-container {
}

.editor-menubar .editor-buttons-container > button {
	flex: 1;
	background: transparent;
	color: #1e2d37;
	border-radius: 0;
	border-bottom: 1px solid #ccc;
	transition: background 0.35s ease-in-out;
}

.editor-menubar .editor-buttons-container > button:hover {
	background: #e4e5e7;
}

.editor-menubar .editor-buttons-container > button + button {
	border-left: 1px solid #ccc;
}

.editor-menubar .editor-buttons-container > button.is-active {
	background: #ccc;
}

.editor-content {
	background-color: #ffffff;
	border-radius: 4px;
}

.editor-content > div {
	min-height: 120px;
	padding: 15px;
}

.editor-content.disabled {
	background-color: #f1f0f1;
}

.editor-content h1,
.editor-content h2,
.editor-content h3 {
	font-weight: 700;
}

.editor-content h1 {
	font-size: 22px;
}

.editor-content h2 {
	font-size: 18px;
}

.editor-content h3 {
	font-size: 15px;
}

.editor-content p:first-child {
	margin-top: 0;
}

.editor-content p:last-child {
	margin-bottom: 0;
}

.editor-content ul {
	list-style-type: circle;
	margin-left: 10px;
}

.editor-content ol {
	list-style-type: decimal;
	margin-left: 10px;
}

.editor-content strong {
	font-weight: 700;
}

.editor-content em {
	font-style: italic;
}

.editor p.is-editor-empty:first-child::before {
	content: attr(data-empty-text);
	float: left;
	color: #aaa;
	pointer-events: none;
	height: 0;
	font-style: italic;
}

.editor-content tr {
	height: auto;
}

.editor-content th {
	background-color: #f1f1f1;
}

.editor-content th,
.editor-content td {
	padding: 5px;
	border: 1px solid #ccc;
}

.accordion.interpolators-accordion {
	margin-bottom: 0;
}

.interpolators-accordion-content {
	max-height: 30vh;
	overflow: auto;
}

.interpolator-category {
	margin: 0;
	padding: 20px 0 5px 0;
	border-bottom: 1px solid #eee;
}

.interpolator-category:first-child {
	padding: 5px 0 5px 0;
}

.interpolator-category:last-child {
	border-bottom: none;
	padding: 20px 0 0 0;
}

.interpolator-category pre {
	margin: 10px 0;
}

.interpolator {
	border-left: 4px solid #eee;
	padding: 0 5px;
	margin: 10px 0;
	line-height: 18px;
}

.interpolator__row__content {
	display: flex;
	flex-direction: column;
	gap: 2px;
	cursor: pointer;
}

.add-variable-icon {
	cursor: pointer;
}

@media screen and (max-width: 800px) {
	.editor-menubar {
		width: calc(100vw - (var(--appMobileHorizontalPadding) * 2 + var(--panelPadding) * 2));
		overflow-x: auto;
	}

	.editor-menubar .editor-buttons-container > button {
		min-width: 40px;
	}
}
</style>
