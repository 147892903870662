var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"accordion",class:[
		{ 'accordion--white': _vm.tWhite },
		{ 'accordion--border': _vm.tBorder },
		{ 'accordion--closed': !_vm.contentVisible },
		{ 'accordion--transparent': _vm.tTransparent },
		{ 'accordion--narrow-gap': _vm.narrowGap },
	]},[_c('header',{staticClass:"accordion__header",class:{ 'header-multiline': _vm.tMultiline }},[(!!_vm.$slots.customActionsLeft)?_c('div',{staticClass:"accordion__header__actions-left"},[_vm._t("customActionsLeft")],2):_vm._e(),_c('h3',{staticClass:"accordion__title",class:{'accordion__title--left-actions': !!_vm.$slots.customActionsLeft},attrs:{"title":_vm.titleAttr,"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.clickListener.apply(null, arguments)}}},[_c('Fa',{staticClass:"accordion-angle-icon",class:{ 'accordion-angle-icon-rotated': _vm.contentVisible },attrs:{"weight":"r","icon":"angle-right"}}),_c('span',{staticClass:"accordion__title__text",class:{ 'accordion-title-multiline': _vm.tMultiline }},[_vm._v(" "+_vm._s(_vm.title)+" ")])],1),_c('div',{staticClass:"accordion__header__actions"},[_c('div',{staticClass:"accordion__header__infos",on:{"click":function($event){_vm.contentVisible ? null : _vm.clickListener()}}},[_vm._t("headerInfo")],2),_c('div',{staticClass:"accordion__header__icons",on:{"click":function($event){_vm.contentVisible ? null : _vm.clickListener()}}},[(_vm.uuid)?_c('AppInfoButton',{attrs:{"uuid":_vm.uuid}}):_vm._e(),_vm._t("headerIcon")],2),(!!_vm.$slots.customActions)?_c('div',{staticClass:"accordion__header__custom-actions"},[_vm._t("customActions")],2):_vm._e()])]),(!!_vm.$slots.alwaysVisible)?_c('div',{staticClass:"accordion__always-visible"},[_vm._t("alwaysVisible")],2):_vm._e(),_c('transition',{attrs:{"name":"accordion"},on:{"enter":_vm.startTransition,"after-enter":_vm.endTransition,"before-leave":_vm.startTransition,"after-leave":_vm.endTransition}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.contentVisible),expression:"contentVisible"}],staticClass:"accordion__animation-container"},[_c('div',{staticClass:"accordion__content"},[_vm._t("default")],2)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }