<template>
	<div class="form-group">
		<label :for="$attrs.id">
			{{ label }}
			<span v-if="'required' in $attrs">*</span>
		</label>
		<AppFileUploadInput v-bind="$attrs" @filesSelected="newAttachmentListener" />

		<ul v-if="attachments !== null" class="attachment-list">
			<li v-for="(attachment, index) in attachments" :key="index" :class="[{'upload-failed': hasUploadFailed(attachment)},]">
				<slot :attachment="attachment" :index="index">
					<Fa weight="r" icon="paperclip" />
					<span v-if="attachment.id">
						<a :href="attachment.file_url" target="_blank">
							{{ attachment.name }}
						</a>
					</span>
					<span v-else>{{ attachment.file.name }}</span>
					<button type="button" @click="deleteAttachmentListener(index)">
						<Fa weight="r" icon="times" class="t-disabled" />
					</button>
				</slot>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'AppAttachments',
	inheritAttrs: false,
	emits: ['input', 'update:deletedAttachments'],
	props: {
		value: {
			type: Array,
			required: true,
		},
		label: {
			type: String,
			default: 'Bilagor',
		},
		deletedAttachments: {
			type: Array,
		},
		additionalProperties: {
			type: Object,
			default: () => ({}),
		},
		showFailedUpload: {
			type: Boolean,
			default: false,
		},
		encryptedPdfAllowed: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		attachments: {
			set(newValue) {
				this.$emit('input', newValue);
			},
			get() {
				return this.value;
			},
		},
		deletedAttachmentsValue: {
			set(newValue) {
				this.$emit('update:deletedAttachments', newValue);
			},
			get() {
				return this.deletedAttachments;
			},
		},
	},
	methods: {
		newAttachmentListener(files) {
			for (const file of files) {
				if (!this.encryptedPdfAllowed) {
					this.pdfIsEncrypted(file)
						.then(() => this.pushAttachment(file))
						.catch((err) => {
							this.flashError(err);
						});
				} else {
					this.pushAttachment(file);
				}
			}
		},
		pushAttachment(file) {
			const newFile = this.renameFile(file, this.getFileName(file.name));

			const addProperties = this.additionalProperties;
			const theNewFile = {
				file: newFile,
			};

			if (Object.keys(addProperties).length) {
				Object.keys(addProperties).forEach((key) => {
					theNewFile[key] = addProperties[key];
				});
			}

			this.attachments.push(theNewFile);
		},
		pdfIsEncrypted(file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.readAsArrayBuffer(file);
				reader.onload = function () {

					let files = new Blob([reader.result], { type: 'application/pdf' });
					files.text().then(x => {
						if (x.includes('Encrypt')) {
							reject(`${file.name} är krypterad och kunde inte väljas`);
						} else {
							resolve();
						}
					});
				};
			});
		},
		deleteAttachmentListener(index) {
			const deleted_attachments = this.attachments.splice(index, 1);
			deleted_attachments.forEach((deleted_attachment) => {
				if (deleted_attachment.id) {
					this.deletedAttachmentsValue.push(deleted_attachment.id);
				}
			});
		},
		getFileName(fileName) {
			if (fileName.length > 22) {
				return fileName.substr(0, 11) + '...' + fileName.substr(-11);
			}

			return fileName;
		},
		renameFile(originalFile, newName) {
			return new File([originalFile], newName, {
				type: originalFile.type,
				lastModified: originalFile.lastModified,
			});
		},
		hasUploadFailed(attachment) {
			if (this.showFailedUpload && attachment.hasOwnProperty.call(attachment, 'is_uploaded')) {
				if (attachment.is_uploaded === false) {
					return true;
				}
			}
			return false;
		},
	},
};
</script>
<style scoped>
.upload-failed {
	border: 1px solid #e33430;
}
</style>
